"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityType = exports.AppType = exports.InviteStatus = exports.UserRole = exports.SortDirection = exports.SortField = void 0;
/**
 * Enumeration of the fields that can be used to sort a list of entities.
 */
var SortField;
(function (SortField) {
    SortField["SORT_ORDER"] = "sortOrder";
    SortField["CREATED_ON"] = "createdOn";
    SortField["CREATED_BY"] = "createdBy";
    SortField["UPDATED_ON"] = "updatedOn";
    SortField["UPDATED_BY"] = "updatedBy";
})(SortField = exports.SortField || (exports.SortField = {}));
/**
 * Enumeration of the two different sort directions.
 */
var SortDirection;
(function (SortDirection) {
    SortDirection["ASCENDING"] = "asc";
    SortDirection["DESCENDING"] = "desc";
})(SortDirection = exports.SortDirection || (exports.SortDirection = {}));
/**
 * The role of a User within a collection.
 */
var UserRole;
(function (UserRole) {
    UserRole["OWNER"] = "owner";
    UserRole["ADMINISTRATOR"] = "administrator";
    UserRole["CONTRIBUTOR"] = "contributor";
    UserRole["VIEWER"] = "viewer";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
/**
 * Enumeration of the status values for an invitation.
 * NOTE: Rejected is not currently being used.
 */
var InviteStatus;
(function (InviteStatus) {
    InviteStatus["ACTIVE"] = "active";
    InviteStatus["ACCEPTED"] = "accepted";
    InviteStatus["REJECTED"] = "rejected";
})(InviteStatus = exports.InviteStatus || (exports.InviteStatus = {}));
/**
 * Enumeration of the different app types.
 */
var AppType;
(function (AppType) {
    AppType["CONTACTS"] = "contacts";
    AppType["CALENDAR"] = "calendar";
    AppType["DOCUMENT_LIBRARY"] = "documentLibrary";
    AppType["APPLICANT_TRACKING"] = "applicantTracking";
    AppType["INTERVIEW_SCHEDULING"] = "interviewScheduling";
    AppType["BLOG"] = "blog";
    AppType["ISSUE_TRACKING"] = "issueTracking";
    AppType["TIMELINE"] = "timeline";
    AppType["JOURNAL"] = "journal";
    AppType["NOTEBOOK"] = "notebook";
    AppType["TASKS"] = "tasks";
})(AppType = exports.AppType || (exports.AppType = {}));
/**
 * Apps are made up of one or more lists of entities. The EntityType enum
 * is used to identify the type of an entity.
 */
var EntityType;
(function (EntityType) {
    EntityType["BLOG_POST"] = "blogPost";
    EntityType["JOURNAL_ENTRY"] = "journalEntry";
    EntityType["NOTEBOOK_PAGE"] = "notebookPage";
    EntityType["TASK"] = "task";
})(EntityType = exports.EntityType || (exports.EntityType = {}));
