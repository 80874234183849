import {
  AppType,
  IApp,
  IDeleteAppResponse,
  ICollection
} from '@groupjitsu/common';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { CollectionDAL } from '../common/CollectionDAL';
import { CollectionNavigation } from '../common/CollectionNavigation';
import groupIcon from '../images/group-32x32.png';
import AddAppletModal from './AddAppModal';
import AppletIcon from './AppIcon';
import './Settings.css';

interface ISettingsPageState {
  collection?: ICollection;
  apps?: IApp[];
  selectedAppletIndex?: number;
  showAddApplet: boolean;
}

class SettingsPage extends Component<
  RouteComponentProps<any>,
  ISettingsPageState
> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      collection: undefined,
      apps: undefined,
      selectedAppletIndex: undefined,
      showAddApplet: false
    };
  }

  public componentDidMount() {
    const collectionId = this.props.match.params.collectionId;

    CollectionDAL.getCollectionInfo(collectionId).then(
      (collection: ICollection) => {
        this.setState({
          collection
        });
      }
    );

    CollectionDAL.getCollectionApps(collectionId).then((apps: IApp[]) => {
      this.setState({
        apps,
        selectedAppletIndex: 0
      });
    });
  }

  public render() {
    const { apps, selectedAppletIndex } = this.state;
    const hasApplets = apps && apps.length > 0;

    return (
      <div className="settings-outer-frame">
        <div className="settings-inner-frame">
          <div>
            <Link
              to={CollectionNavigation.buildCollectionUrl(
                this.props.match.params.collectionId
              )}
            >
              &lt; Back
            </Link>
            {'   '}
            <Link
              to={
                CollectionNavigation.buildCollectionUrl(
                  this.props.match.params.collectionId
                ) + '/membership'
              }
            >
              Membership &gt;
            </Link>
          </div>

          {this.state.collection && (
            <div className="settings-section">
              <h4 className="settings-section-header">Group Settings</h4>
              <div className="d-flex">
                <div className="group-settings-left">
                  <img id="group-icon" src={groupIcon} alt="Group icon" />
                  <button id="change-group-icon" className="btn">
                    Change...
                  </button>
                </div>
                <div className="flex-grow-1">
                  <div className="form-group">
                    <label htmlFor="groupName">Group Name</label>
                    <input
                      id="groupName"
                      type="text"
                      className="form-control"
                      value={this.state.collection.name}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      className="form-control"
                      value={this.state.collection.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {apps && (
            <div className="settings-section applet-settings">
              <h4 className="settings-section-header">Applets</h4>

              <div className="d-flex">
                <div className="group-settings-left">
                  <select
                    id="select-applet"
                    className="form-control"
                    size={10}
                    value={
                      hasApplets ? apps[selectedAppletIndex!].appId : undefined
                    }
                    onChange={this.handleSelectChange}
                  >
                    {apps.map(applet => (
                      <option key={applet.appId} value={applet.appId}>
                        {applet.name}
                      </option>
                    ))}
                  </select>
                  <button
                    id="new-applet"
                    className="btn"
                    onClick={this.handleAddAppletClick}
                  >
                    + New Applet
                  </button>
                </div>

                <div className="flex-grow-1">
                  <div>
                    {hasApplets && (
                      <div className="d-flex">
                        <div className="mr-2">
                          <AppletIcon
                            appletType={apps[selectedAppletIndex!].type}
                            large={true}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <div id="applet-name">
                            {apps[selectedAppletIndex!].name}
                          </div>
                          <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua...
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="applet-commands">
                      <button
                        className="btn"
                        onClick={this.handleMoveUp}
                        disabled={this.state.selectedAppletIndex! <= 0}
                      >
                        Move Up
                      </button>
                      <button className="btn">Settings</button>
                    </div>

                    <div className="applet-commands">
                      <button
                        className="btn"
                        onClick={this.handleMoveDown}
                        disabled={
                          this.state.selectedAppletIndex! >= apps.length - 1
                        }
                      >
                        Move Down
                      </button>
                      <button className="btn" onClick={this.handleDeleteApplet}>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <AddAppletModal
                isVisible={this.state.showAddApplet}
                onRequestClose={this.handleAddAppletRequestClose}
                onAddApplet={this.handleAddApplet}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  private handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
    this.setState({
      selectedAppletIndex: e.currentTarget.selectedIndex
    });
  };

  private handleAddAppletClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({
      showAddApplet: true
    });
  };

  private handleAddAppletRequestClose = () => {
    this.setState({
      showAddApplet: false
    });
  };

  private handleAddApplet = (type: AppType, name: string) => {
    this.setState({
      showAddApplet: false
    });
    CollectionDAL.addCollectionApp(
      this.props.match.params.collectionId,
      type,
      name
    )
      .then((applet: IApp) => {
        const apps = this.state.apps!.slice(0);
        apps.push(applet);
        this.setState({
          apps
        });
      })
      .catch((reason: any) => {
        alert('An unexpected error occurred while trying to add the applet.');
      });
  };

  private handleMoveUp = (e: React.FormEvent<HTMLButtonElement>) => {
    this.handleMove(e, true);
  };

  private handleMoveDown = (e: React.FormEvent<HTMLButtonElement>) => {
    this.handleMove(e, false);
  };

  private handleMove = (
    e: React.FormEvent<HTMLButtonElement>,
    moveUp: boolean
  ) => {
    const applet = this.state.apps![this.state.selectedAppletIndex!];
    CollectionDAL.reorderApp(applet.collectionId, applet.appId, moveUp)
      .then((result: any) => {
        // Move was successful.  Fetch the ordered list of apps
        // again and select the item that we just moved in the list.
        CollectionDAL.getCollectionApps(
          this.props.match.params.collectionId
        ).then((apps: IApp[]) => {
          const selectedAppletIndex = apps.findIndex(
            a => a.appId === result.appId
          );
          this.setState({
            apps,
            selectedAppletIndex
          });
        });
      })
      .catch((reason: any) => {
        alert('An unexpected error occurred while moving the applet.');
      });
  };

  private handleDeleteApplet = (e: React.FormEvent<HTMLButtonElement>) => {
    const applet = this.state.apps![this.state.selectedAppletIndex!];
    CollectionDAL.deleteCollectionApp(applet.collectionId, applet.appId)
      .then((response: IDeleteAppResponse) => {
        const apps = this.state.apps!.filter(a => a.appId !== response.appId);
        const selectedAppletIndex = Math.min(
          this.state.selectedAppletIndex!,
          apps.length - 1
        );
        this.setState({
          apps,
          selectedAppletIndex
        });
      })
      .catch((reason: any) => {
        alert(
          'An unexpected error occurred while trying to delete the applet.'
        );
      });
  };
}

export default withRouter(SettingsPage);
