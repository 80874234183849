import { IValidateLoginTokenResponse } from '@groupjitsu/common';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CollectionNavigation } from '../common/CollectionNavigation';
import { UsersDAL } from '../common/UsersDAL';
import logo from '../images/logo-200x200.png';
import './Landing.css';

interface ILandingPageState {
  showLandingPage: boolean;
}

class LandingPage extends Component<RouteComponentProps, ILandingPageState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      showLandingPage: false
    };
  }

  componentDidMount() {
    // If the user has previously logged in and asked to be
    // remembered, just navigate to the user's default collection.
    // Otherwise show the landing page.
    UsersDAL.validateLoginToken().then(
      (response: IValidateLoginTokenResponse) => {
        if (response.isValid) {
          CollectionNavigation.navigateToCollection(
            response.defaultCollectionId!,
            this.props.history
          );
        } else {
          this.setState({
            showLandingPage: true
          });
        }
      }
    );
  }

  render() {
    if (!this.state.showLandingPage) {
      return <div />;
    }

    return (
      <div className="app">
        <div className="mx-auto mx-auto col-narrow-fixed title-text">
          <h1>Kumomojo</h1>
          <h4 className="brand-tagline">
            Cloud-based applications designed to help you be more productive.
          </h4>
          <img src={logo} alt="App logo" />
        </div>

        <div className="mx-auto col-narrow-fixed informative-text">
          Kumomojo is currently in Beta and all sign-ups are by invitation only.
          If you have an invitation code, please click the Sign Up button below
          to get started.
        </div>

        <div>
          <a
            className="mx-auto btn btn-primary btn-narrow-fixed"
            href="signup.php"
          >
            Sign Up
          </a>
        </div>

        <div className="mx-auto col-narrow-fixed informative-text">
          Already a member? Welcome back!
        </div>

        <div>
          <a
            className="mx-auto btn btn-outline-secondary btn-narrow-fixed"
            href="login"
          >
            Log in
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(LandingPage);
