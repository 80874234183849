import { ICollection, IUserCollection, UserRole } from '@groupjitsu/common';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { CollectionNavigation } from '../common/CollectionNavigation';
import AddCollectionModal from './AddCollectionModal';
import './Settings.css';
import { UsersDAL } from '../common/UsersDAL';

interface IUserCollectionsPageState {
  collections?: IUserCollection[];
  showAddCollection: boolean;
}

class UserCollectionsPage extends Component<
  RouteComponentProps<any>,
  IUserCollectionsPageState
> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      collections: undefined,
      showAddCollection: false
    };
  }

  public componentDidMount() {
    UsersDAL.getUserCollections()
      .then((collections: IUserCollection[]) => {
        this.setState({
          collections
        });
        return null;
      })
      .catch(error => {
        alert('error');
      });
  }

  public render() {
    return (
      <div className="settings-outer-frame">
        <div className="settings-inner-frame">
          <div>
            <Link
              to={CollectionNavigation.buildAppUrl(
                this.props.match.params.collectionId,
                'someapplet'
              )}
            >
              &lt; Back
            </Link>

            <div className="settings-section">
              <h4 className="settings-section-header">My Collections</h4>
              <button className="btn" onClick={this.handleAddCollectionClick}>
                Add Collection
              </button>
            </div>
            {this.state.collections &&
              this.state.collections.map(c => (
                <div>
                  {c.name} - {c.role}
                </div>
              ))}
          </div>
        </div>

        <AddCollectionModal
          isVisible={this.state.showAddCollection}
          onRequestClose={this.handleAddCollectionRequestClose}
          onAddCollection={this.handleAddCollection}
        />
      </div>
    );
  }

  private handleAddCollectionClick = () => {
    this.setState({
      showAddCollection: true
    });
  };

  private handleAddCollectionRequestClose = () => {
    this.setState({
      showAddCollection: false
    });
  };

  private handleAddCollection = (collection: ICollection) => {
    const userCollection = {
      collectionId: collection.collectionId,
      name: collection.name,
      role: UserRole.OWNER
    } as IUserCollection;
    this.setState(prevState => {
      return { collections: [...prevState.collections!, userCollection] };
    });
  };
}

export default withRouter(UserCollectionsPage);
