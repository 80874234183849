import filesize from 'filesize';
import numeral from 'numeral';

const FORMAT_PERCENTAGE = '0%';

/**
 * Provides static methods which format values for display.
 */
class ValueFormatter {
  /**
   * Formats a UTC date in ISO 8601 format for display.
   * The date value is converted to locale time before formatting.
   *
   * CONSIDER: At some point we may want to bring in moment.js or
   * something similar.  For now this is all we need.
   *
   * @param {string} utcDateString - The UTC date string to display.
   */
  public static formatUtcDateString(utcDateString?: string) {
    if (!utcDateString) {
      return '';
    }


    return ValueFormatter.dateFromUtcDateString(utcDateString)
    .toLocaleString();
  }

  /**
   * Formats a UTC date in ISO 8601 format as a full date.
   * 
   * @param utcDateString - The UTC date string to display.
   */
  public static formatAsFullDate(utcDateString?: string) {
    if (!utcDateString) {
      return '';
    }

    const date = ValueFormatter.dateFromUtcDateString(utcDateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}
    return date.toLocaleDateString('en-US', options);
  }

  /**
   * Formats a UTC date in ISO 8601 format as a time with AM/PM.
   * 
   * @param utcDateString - The UTC date string to display.
   */
  public static formatAsFullTime(utcDateString: string) {
    if (!utcDateString) {
      return '';
    }

    const date = ValueFormatter.dateFromUtcDateString(utcDateString);
    const hours = date.getHours();
    return (hours > 12 ? (hours - 12) : hours) 
        + ':' + numeral(date.getMinutes()).format('00') 
        + (date.getHours() >= 12 ? ' PM' : ' AM');
  }

  /**
   * Formats a number as a percentage.
   *
   * @param {number} numericPercentage
   */
  public static formatPercentage(numericPercentage: number) {
    return numeral(numericPercentage).format(FORMAT_PERCENTAGE);
  }

  /**
   * Formats a count of bytes into a more readable file size string.
   * @param fileSize - The count of bytes to format.
   */
  public static formatFileSize(fileSize: number) {
    return filesize(fileSize);
  }

  /**
   * Formats a string template containing replacement tokens with
   * values provided by the caller.  Template string should be of the following
   * form:
   * 
   *    The quick brown {0} jumped over the lazy {1}.
   * 
   * @param {string} template - String template to format.
   * @param {any[]} args - List of arguments to use in place of tokens.
   */
  public static formatString(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, (match: string, num: number) => {
      return typeof args[num] !== 'undefined'
        ? args[num]
        : match
        ;
    });
  }

  /**
   * Returns a Date object from a UTC date string.
   * 
   * @param utcDateString - UTC date string to convert into a date.
   */
  private static dateFromUtcDateString(utcDateString: string) {
    // If the string ends with a time zone designator offset,
    // then we expect the offset to be 0.  If we find one of these
    // designators, convert it to the 'Z' suffix.
    if (utcDateString.endsWith('+0000')) {
      utcDateString = utcDateString.substring(0, utcDateString.length - 5);
      utcDateString += 'Z';
    }

    // Should be in YYYY-MM-DDTHH:MM:SSZ format.  Split it up and convert.
    const parts = utcDateString.split(/[-T:Z]/gi);
    return new Date(
      Date.UTC(
        +parts[0],
        +parts[1] - 1,
        +parts[2],
        +parts[3],
        +parts[4],
        +parts[5]
      ));
  }
}

export default ValueFormatter;
