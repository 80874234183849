import {
  EntityType,
  IDeleteEntityResponse,
  IJournalEntry,
  SortDirection,
  SortField
} from '@groupjitsu/common';
import Promise from 'bluebird';
import $ from 'jquery';
import React, { Component } from 'react';
import { EntityDAL } from '../../common/EntityDAL';
import { JournalEntryViewModel } from '../../viewmodels/JournalEntryViewModel';
import './App.css';
import { IAppProps, IAppState } from './common';
import './JournalApp.css';

interface IJournalAppState extends IAppState {
  entries?: JournalEntryViewModel[];
  selected?: JournalEntryViewModel;
  isEditing: boolean;
}

export class JournalApp extends Component<IAppProps, IJournalAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      entries: undefined,
      selected: undefined,
      isEditing: false
    };
  }

  public componentDidMount() {
    this.getData();
  }

  public componentDidUpdate(prevProps: IAppProps, prevState: IJournalAppState) {
    if (!prevState.isEditing && this.state.isEditing) {
      $('#createdOnDate').select().focus();
    }

    if (prevProps.app.appId !== this.props.app.appId) {
      this.getData();
    }
  }

  public render() {
    const { entries, selected } = this.state;

    return (
      <div className="applet-container">
        <div className="journal-entry-list">
          <div className="applet-command-bar border-bottom d-flex flex-row">
            <form className="form-inline my-2 my-lg-0 mr-auto">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search Entries"
                aria-label="Search"
              />
            </form>

            <button
              type="button"
              id="new-entry-button"
              className="btn btn-outline-secondary my-2"
              onClick={this.handleAddItem}
            >
              <i className="fas fa-plus" />
            </button>
          </div>

          {entries &&
            entries.map(entry => (
              <div
                className={`journal-entry-container ${
                  selected && entry.entityId === selected.entityId
                    ? 'journal-entry-selected'
                    : ''
                }`}
                key={entry.entityId}
                data-entry-id={entry.entityId}
                onClick={this.handleSelectListItem}
              >
                <div className="journal-entry-header">
                  {entry.createdOnDate}
                </div>
                <div className="journal-entry-body">
                  <p>{entry.body}</p>
                </div>
              </div>
            ))}
        </div>

        <div className="journal-entry-detail">
          <div className="applet-command-bar d-flex flex-row-reverse p-2">
            <button
              type="button"
              id="edit-entry-button"
              className="btn btn-outline-secondary"
              disabled={this.state.isEditing}
              onClick={this.handleEditItem}
            >
              <i className="fas fa-edit pr-2" />
              Edit
            </button>
            <button
              type="button"
              id="delete-entry-button"
              className="btn btn-outline-secondary mx-2"
              disabled={this.state.isEditing}
              onClick={this.handleDeleteItem}
            >
              <i className="fas fa-trash-alt pr-2" />
              Delete
            </button>
          </div>

          {selected && !this.state.isEditing && (
            <div className="journal-entry-detail-form">
              <div
                className="journal-entry-detail-date"
                style={{
                  border: '2px solid transparent',
                  paddingTop: '1px',
                  paddingBottom: '1px'
                }}
              >
                {selected.createdOnDate}
              </div>

              <div
                className="journal-entry-detail-time"
                style={{
                  border: '2px solid transparent',
                  paddingTop: '1px',
                  paddingBottom: '1px'
                }}
              >
                {selected.createdOnTime}
              </div>

              <div
                className="journal-entry-detail-body"
                style={{ border: '1px solid transparent', padding: '2px' }}
              >
                {selected.body}
              </div>
            </div>
          )}

          {selected && this.state.isEditing && (
            <div
              className="journal-entry-detail"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '90%'
              }}
            >
              <input
                id="createdOnDate"
                name="createdOnDate"
                type="text"
                className="journal-entry-detail-date"
                style={{ display: 'block', width: '100%' }}
                value={selected.createdOnDate}
                onChange={this.handleInputChange}
              />

              <input
                type="text"
                name="createdOnTime"
                className="journal-entry-detail-time"
                style={{ display: 'block', width: '100%' }}
                value={selected.createdOnTime}
                onChange={this.handleInputChange}
              />

              <textarea
                name="body"
                className="journal-entry-detail-body"
                style={{ display: 'block', width: '100%', flex: '1' }}
                value={selected.body}
                onChange={this.handleFieldChange}
              />

              <div style={{ textAlign: 'right', paddingTop: '4px' }}>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={this.handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleSaveEdit}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private getData = () => {
    EntityDAL.getEntities(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.JOURNAL_ENTRY,
      SortField.CREATED_ON,
      SortDirection.DESCENDING,
      JournalEntryViewModel
    ).then((entries: JournalEntryViewModel[]) => {
      this.setState({
        entries,
        selected:
          entries && entries.length
            ? new JournalEntryViewModel(entries[0])
            : undefined
      });
      return null;
    });
  };

  private handleAddItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const createdOn = new Date().toISOString();
    const newEntry = new JournalEntryViewModel({
      entityId: '-1',
      createdOn,
      updatedOn: createdOn,
      body: ''
    } as IJournalEntry);

    let { entries } = this.state;
    if (!entries) {
      entries = [newEntry];
    } else {
      entries.unshift(newEntry);
    }

    this.setState({
      entries,
      selected: new JournalEntryViewModel(newEntry),
      isEditing: true
    });
  };

  private handleEditItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      isEditing: true
    });
  };

  private handleDeleteItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    let { entries, selected } = this.state;

    EntityDAL.deleteEntity(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.JOURNAL_ENTRY,
      selected!.entityId
    ).then((result: IDeleteEntityResponse) => {
      entries = entries!.filter(entry => entry.entityId !== result.entityId);
      this.setState({
        entries,
        selected: entries.length
          ? new JournalEntryViewModel(entries[0])
          : undefined
      });
    });
  };

  private handleSelectListItem = (e: React.MouseEvent<HTMLElement>) => {
    const entryId = e.currentTarget.dataset['entryId'];
    const entry = this.state.entries!.find(entry => entry.entityId === entryId);
    const selected = new JournalEntryViewModel(entry!);
    this.setState({
      selected
    });
  };

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.updateSelectedValue(e.currentTarget.name, e.currentTarget.value);
  };

  private handleFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.updateSelectedValue(e.currentTarget.name, e.currentTarget.value);
  };

  private updateSelectedValue = (name: string, value: string) => {
    const { selected } = this.state;
    (selected as any)![name] = value;
    this.setState({
      selected: selected
    });
  };

  private handleSaveEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const postBody = this.state.selected!.toModel();
    const isNew = postBody.entityId === '-1';

    const promise: Promise<IJournalEntry> = isNew
      ? EntityDAL.addEntity(
          this.props.app.collectionId,
          this.props.app.appId,
          EntityType.JOURNAL_ENTRY,
          postBody,
          JournalEntryViewModel
        )
      : EntityDAL.updateEntity(
          this.props.app.collectionId,
          this.props.app.appId,
          EntityType.JOURNAL_ENTRY,
          this.state.selected!.entityId,
          postBody,
          JournalEntryViewModel
        );

    promise
      .then((result: IJournalEntry) => {
        const { entries, selected } = this.state;
        const index = entries!.findIndex(
          e => e.entityId === selected!.entityId
        );
        const newEntries = entries!.slice();
        newEntries[index] = new JournalEntryViewModel(result);
        this.setState({
          entries: newEntries,
          selected: new JournalEntryViewModel(result),
          isEditing: false
        });
      })
      .catch((error: any) => {
        alert('Error!');
      });
  };

  private handleCancelEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const original = this.state.entries!.find(
      e => e.entityId === this.state.selected!.entityId
    )!;
    this.setState({
      isEditing: false,
      selected: new JournalEntryViewModel(original)
    });
  };
}
