import {
  EntityType,
  ITask,
  IDeleteEntityResponse,
  SortDirection,
  SortField
} from '@groupjitsu/common';
import React, { Component } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { EntityDAL, Session } from '../../common';
import { TaskViewModel } from '../../viewmodels';
import {
  CheckboxField,
  ICheckboxFieldChangeEvent
} from '../controls/CheckboxField';
import { ErrorBanner } from '../controls/ErrorBanner';
import { TextField, ITextFieldCommitEvent } from '../controls/TextField';
import './App.css';
import { IAppProps, IAppState } from './common';
import './TaskApp.css';

interface ITaskAppState extends IAppState {
  tasks?: TaskViewModel[];
  selected?: TaskViewModel;
  editedValue?: string;
  newTaskValue: string;
}

export class TaskApp extends Component<IAppProps, ITaskAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      newTaskValue: ''
    };
  }

  public componentDidMount() {
    this.getData();
  }

  public componentDidUpdate(prevProps: IAppProps) {
    if (prevProps.app.appId !== this.props.app.appId) {
      this.getData();
    }
  }

  public render() {
    const { tasks, selected } = this.state;

    return (
      <div className="applet-container">
        <div className="task-list">
          <ErrorBanner error={this.state.error} />

          <div className="applet-command-bar border-bottom d-flex flex-row">
            <form className="form-inline my-2 my-lg-0 mr-auto">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search Tasks"
                aria-label="Search"
              />
            </form>
          </div>

          {tasks &&
            tasks.map(task => (
              <div
                className={`task-container ${
                  selected && task.entityId === selected.entityId
                    ? 'task-selected'
                    : ''
                }`}
                key={task.entityId}
                data-entity-id={task.entityId}
                onClick={this.handleSelectListItem}
              >
                <div className="task-body">
                  <CheckboxField
                    name="isComplete"
                    initialValue={task.isComplete}
                    onChange={this.handleCheckboxChange}
                    dataContext={task.entityId}
                  />
                  <span>{task.title}</span>
                </div>
              </div>
            ))}

          <TextField
            placeholder="Add new task..."
            initialValue=""
            onCommit={this.handleNewTaskCommit}
            clearOnCommit={true}
            className="my-2"
          />
        </div>

        <div className="task-detail">
          <div className="applet-command-bar d-flex flex-row-reverse p-2">
            <button
              type="button"
              id="delete-entry-button"
              className="btn btn-outline-secondary mx-2"
              onClick={this.handleDeleteItem}
            >
              <i className="fas fa-trash-alt pr-2" />
              Delete
            </button>
          </div>

          {selected && (
            <div className="task-detail-container">
              <Form className="d-flex">
                <div>
                  <FormGroup>
                    <CheckboxField
                      name="isComplete"
                      initialValue={selected.isComplete}
                      onChange={this.handleCheckboxChange}
                    />
                  </FormGroup>
                </div>

                <div style={{ flex: '1' }}>
                  <FormGroup>
                    <TextField
                      name="title"
                      initialValue={selected.title}
                      onCommit={this.handleTextFieldCommit}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Description</Label>
                    <TextField
                      lines={3}
                      name="description"
                      initialValue={selected.description}
                      onCommit={this.handleTextFieldCommit}
                    />
                  </FormGroup>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    );
  }

  private getData = () => {
    EntityDAL.getEntities(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.TASK,
      SortField.SORT_ORDER,
      SortDirection.ASCENDING,
      TaskViewModel
    ).then((tasks: TaskViewModel[]) => {
      this.setState({
        tasks,
        selected:
          tasks && tasks.length ? new TaskViewModel(tasks[0]) : undefined
      });
      return null;
    });
  };

  private handleNewTaskCommit = (e: ITextFieldCommitEvent) => {
    const postBody = {
      createdBy: Session.getUserId(),
      title: e.value,
      description: '',
      isComplete: false
    } as ITask;

    EntityDAL.addEntity(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.TASK,
      postBody,
      TaskViewModel
    )
      .then((newTask: TaskViewModel) => {
        this.setState(prevState => {
          const tasks = this.state.tasks!.slice();
          tasks.push(newTask);
          return {
            tasks,
            newTaskValue: '',
            error: undefined
          };
        });
        return null;
      })
      .catch((error: any) => {
        this.setState({
          error: 'An unexpected error occurred while saving the new task.'
        });
      });

    // TODO: Event handler should be able to return a promise
    // which returns the boolean result.
    return true;
  };

  private handleDeleteItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    let { tasks, selected } = this.state;

    EntityDAL.deleteEntity(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.TASK,
      selected!.entityId
    )
      .then((result: IDeleteEntityResponse) => {
        tasks = tasks!.filter(task => task.entityId !== result.entityId);
        this.setState({
          tasks,
          selected: tasks.length ? new TaskViewModel(tasks[0]) : undefined
        });
      })
      .catch(err => {
        this.setState({
          error: 'An unexpected error occurred deleting the task.'
        });
      });
  };

  private handleSelectListItem = (e: React.MouseEvent<HTMLElement>) => {
    const entityId = e.currentTarget.dataset.entityId!;
    let selected = this.state.selected;
    if (!selected || selected.entityId !== entityId) {
      const task = this.findTask(entityId);
      const selected = new TaskViewModel(task);
      this.setState({
        selected
      });
    }
  };

  private handleCheckboxChange = (e: ICheckboxFieldChangeEvent) => {
    let { selected } = this.state;

    if (e.dataContext && e.dataContext !== selected?.entityId) {
      const entry = this.findTask(e.dataContext);
      selected = new TaskViewModel(entry);
    } else {
      selected = new TaskViewModel(selected!);
    }

    selected.isComplete = e.value;
    this.saveTask(selected);
    return true;
  };

  private findTask(entityId: string) {
    return this.state.tasks!.find(task => task.entityId === entityId)!;
  }

  private handleTextFieldCommit = (e: ITextFieldCommitEvent) => {
    try {
      this.updateSelectedValue(e.fieldName!, e.value);
      this.saveTask(this.state.selected!);
      return true;
    } catch (e) {
      return false;
    }
  };

  private updateSelectedValue = (name: string, value: string) => {
    const { selected } = this.state;
    (selected as any)![name] = value;
    this.setState({
      selected: selected
    });
  };

  private saveTask = (task: TaskViewModel) => {
    EntityDAL.updateEntity(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.TASK,
      task.entityId,
      task.toModel(),
      TaskViewModel
    )
      .then((result: ITask) => {
        this.setState({
          tasks: this.state.tasks?.map(task =>
            task.entityId === result.entityId ? new TaskViewModel(result) : task
          ),
          selected: new TaskViewModel(result),
          error: undefined
        });
      })
      .catch((error: any) => {
        this.setState({
          error: 'An unexpected error occurred while updating the task.'
        });
      });
  };
}
