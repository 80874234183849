import {
  AppType,
  IApp,
  ICollectionInfo,
  IUserCollection
} from '@groupjitsu/common';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import {
  BlogApp,
  ContactsApp,
  JournalApp,
  NotebookApp,
  TaskApp,
  TimelineApp
} from './apps';
import './Collection.css';
import { CollectionDAL } from '../common/CollectionDAL';
import { Session } from '../common/Session';
import { UsersDAL } from '../common/UsersDAL';
import { CollectionNavigation } from '../common/CollectionNavigation';
import groupIcon from '../images/group-32x32.png';
import AppIcon from './AppIcon';

interface ICollectionPageProperties extends RouteComponentProps<any> {}

interface ICollectionPageState {
  collectionId?: string;
  name?: string;
  applets?: IApp[];
  membership?: IUserCollection[];
}

class CollectionPage extends Component<
  ICollectionPageProperties,
  ICollectionPageState
> {
  constructor(props: ICollectionPageProperties) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.getData();
  }

  public componentDidUpdate(prevProps: ICollectionPageProperties) {
    if (
      this.props.match.params.collectionId !==
      prevProps.match.params.collectionId
    ) {
      this.getData();
    }
  }

  public render() {
    const { applets, name } = this.state;
    const selectedApp =
      this.props.match.params.appName &&
      this.props.match.params.appName.toLowerCase();

    if (!applets) {
      return <div />;
    }

    return (
      <main>
        <div className="sidebar">
          <div
            className="dropdown-toggle group-selector"
            data-toggle="dropdown"
          >
            <img src={groupIcon} alt="Collection icon" />
            <span id="group-name">{name}</span>
          </div>

          <DropdownMenu>
            <DropdownItem header>Recent Collections</DropdownItem>
            <DropdownItem>{name}</DropdownItem>
            <DropdownItem divider />
            {this.state.membership &&
              this.state.membership.map(g => (
                <DropdownItem
                  key={g.collectionId}
                  data-collection-id={g.collectionId}
                  onClick={this.handleCollectionClick}
                >
                  {g.name}
                </DropdownItem>
              ))}
            <DropdownItem onClick={this.handleAllCollectionsClick}>
              All Collections
            </DropdownItem>
          </DropdownMenu>

          {applets &&
            applets.map((applet: IApp) =>
              this.renderSidebarItem(applet, selectedApp)
            )}

          <div className="sidebar-footer">
            <a
              href={CollectionNavigation.buildSettingsUrl(
                this.props.match.params.collectionId
              )}
            >
              Settings
            </a>
          </div>
        </div>

        <div className="sidebar-resize">
          <span className="sidebar-collapse">
            <i className="fas fa-angle-left" />
          </span>
        </div>
        {this.renderApp()}
      </main>
    );
  }

  private renderSidebarItem = (applet: IApp, selectedApp: string) => {
    const isSelected = applet.name.toLowerCase() === selectedApp;

    return (
      <div
        className={
          'sidebar-item' + (isSelected ? ' sidebar-item-selected' : '')
        }
        key={applet.appId}
        data-target-url={
          isSelected
            ? ''
            : CollectionNavigation.buildAppUrl(
                this.props.match.params.collectionId,
                applet.name
              )
        }
        onClick={this.handleAppClick}
      >
        <AppIcon appletType={applet.type} />
        <span>{applet.name}</span>
      </div>
    );
  };

  private getData = () => {
    CollectionDAL.getCollectionInfo(this.props.match.params.collectionId)
      .then((data: ICollectionInfo) => {
        this.setState(
          {
            collectionId: data.collectionId,
            name: data.name,
            applets: data.apps
          },
          this.ensureAppSelected
        );
        return null;
      })
      .catch((error: any) => {
        alert('UNDONE error!');
      });

    UsersDAL.getUserCollections().then((membership: IUserCollection[]) => {
      this.setState({
        membership
      });
    });
  };

  private handleCollectionClick = (e: React.MouseEvent<HTMLElement>) => {
    CollectionNavigation.navigateToCollection(
      e.currentTarget.dataset.collectionId!,
      this.props.history
    );
  };

  private handleAllCollectionsClick = () => {
    this.props.history.push(
      CollectionNavigation.buildUserCollectionsPageUrl(Session.getUserId()!)
    );
  };

  private handleAppClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const url = e.currentTarget.dataset.targetUrl;
    if (url) {
      this.props.history.push(url);
    }
  };

  private ensureAppSelected() {
    if (!this.props.match.params.appName) {
      if (!this.state.applets || this.state.applets.length === 0) {
        const url = CollectionNavigation.buildSettingsUrl(
          this.props.match.params.collectionId
        );
        this.props.history.replace(url);
      } else {
        const applet = this.state.applets![0].name;
        const url = CollectionNavigation.buildAppUrl(
          this.props.match.params.collectionId,
          applet
        );
        this.props.history.replace(url);
      }
    }
  }

  private renderApp() {
    const app = this.state.applets!.find(a => {
      return a.name.toLowerCase() === this.props.match.params.appName;
    });

    if (!app) {
      return <div>Invalid app name.</div>;
    }

    switch (app.type) {
      case AppType.BLOG:
        return <BlogApp app={app} />;

      case AppType.CONTACTS:
        return <ContactsApp />;

      case AppType.TIMELINE:
        return <TimelineApp />;

      case AppType.JOURNAL:
        return <JournalApp app={app} />;

      case AppType.NOTEBOOK:
        return <NotebookApp app={app} />;

      case AppType.TASKS:
        return <TaskApp app={app} />;

      default:
        return <div>Invalid app name.</div>;
    }
  }
}

export default withRouter(CollectionPage);
