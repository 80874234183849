import React, { Component } from 'react';
import { AppType } from '@groupjitsu/common';
import './AppIcon.css';

interface IAppIconProps {
  appletType: AppType;
  large?: boolean;
}

class AppIcon extends Component<IAppIconProps, {}> {
  render() {
    const baseStyles =
      'fas ' + (this.props.large ? 'applet-icon-large' : ' applet-icon');
    switch (this.props.appletType) {
      case AppType.CONTACTS:
        return <i className={baseStyles + ' fa-address-book'} />;
      case AppType.CALENDAR:
        return <i className={baseStyles + ' fa-calendar'} />;
      case AppType.DOCUMENT_LIBRARY:
        return <i className={baseStyles + ' fa-folder-open'} />;
      case AppType.APPLICANT_TRACKING:
        return <i className={baseStyles + ' fa-user-circle'} />;
      case AppType.INTERVIEW_SCHEDULING:
        return <i className={baseStyles + ' fa-clock'} />;
      case AppType.BLOG:
        return <i className={baseStyles + ' fa-blog'} />;
      case AppType.ISSUE_TRACKING:
        return <i className={baseStyles + ' fa-clipboard-list'} />;
      case AppType.TIMELINE:
        return <i className={baseStyles + ' fa-project-diagram'} />;
      case AppType.JOURNAL:
        return <i className={baseStyles + ' fa-book'} />;
      case AppType.NOTEBOOK:
        return <i className={baseStyles + ' fa-book-open'} />;
      case AppType.TASKS:
        return <i className={baseStyles + ' fa-tasks'} />;
    }
  }
}

export default AppIcon;
