import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteDispatch from './RouteDispatch';

class App extends Component {
  render() {
    return (<Router><RouteDispatch/></Router>);
  }
}

export default App;
