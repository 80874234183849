import classnames from 'classnames';
import * as React from 'react';
import { NotebookPageViewModel } from '../../../viewmodels';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface INotebookNavProps {
  pages?: NotebookPageViewModel[];
  selected?: NotebookPageViewModel;
  onAddPage: () => void;
  onSelectPage: (page: NotebookPageViewModel) => void;
  onReorderPage: (page: NotebookPageViewModel, sortOrder: number) => void;
}

export const NotebookNav = (props: INotebookNavProps) => {
  const { pages, selected, onAddPage, onSelectPage, onReorderPage } = props;

  const handleAddPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onAddPage();
  };

  const handleSelect = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const entityId = e.currentTarget.dataset['entityId'];
      const entry = pages!.find(page => page.entityId === entityId)!;
      onSelectPage(entry);
    },
    [pages, onSelectPage]
  );

  const handleDragEnd = React.useCallback(
    result => {
      const page = pages!.find(p => p.sortOrder === result.source.index)!;
      const destIndex = result.destination.index;
      onReorderPage(page, destIndex);
    },
    [pages, onReorderPage]
  );

  return (
    <div className="notebook-page-list">
      <div className="applet-command-bar border-bottom d-flex flex-row">
        <form className="form-inline my-2 my-lg-0 mr-auto">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search Notebook"
            aria-label="Search"
          />
        </form>

        <button
          type="button"
          id="new-entry-button"
          className="btn btn-outline-secondary my-2"
          onClick={handleAddPage}
        >
          <i className="fas fa-plus" />
        </button>
      </div>

      <div className="notebook-page-list-header">Pages</div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="notebook-page-list">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {pages &&
                pages.map((page, index) => (
                  <Draggable
                    key={page.entityId}
                    draggableId={page.entityId}
                    index={page.sortOrder}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classnames({
                          'notebook-page-list-item': true,
                          'notebook-page-list-item-selected':
                            selected && page.entityId === selected.entityId,
                          'notebook-page-list-item-dragging':
                            snapshot.isDragging
                        })}
                        key={page.entityId}
                        data-entity-id={page.entityId}
                        onClick={handleSelect}
                      >
                        {page.title}
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
