import { INotebookPage } from '@groupjitsu/common';
import { EntityViewModel } from './EntityViewModel';

/**
 * View model for a page in a Notebook applet.
 */
export class NotebookPageViewModel extends EntityViewModel {
  public title: string;
  public body: string;

  constructor(source: INotebookPage | NotebookPageViewModel) {
    super(source);
    this.title = source.title;
    this.body = source.body;
  }

  public toModel(): INotebookPage {
    return {
      ...super.toModel(),
      title: this.title,
      body: this.body
    };
  }
}
