import {
  EntityType,
  IBlogPost,
  IDeleteEntityResponse,
  SortDirection,
  SortField
} from '@groupjitsu/common';
import Promise from 'bluebird';
import $ from 'jquery';
import React, { Component } from 'react';
import { EntityDAL } from '../../common/EntityDAL';
import { BlogPostViewModel } from '../../viewmodels/BlogPostViewModel';
import './App.css';
import { IAppProps, IAppState } from './common';
import './BlogApp.css';

/**
 * NOTE: This applet needs work.  It was originally set up for
 * as the Journal applet.  It needs improvement and fixes to
 * actually be a Blog applet.
 */

interface IBlogAppState extends IAppState {
  posts?: BlogPostViewModel[];
  selected?: BlogPostViewModel;
  isEditing: boolean;
}

export class BlogApp extends Component<IAppProps, IBlogAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      posts: undefined,
      selected: undefined,
      isEditing: false
    };
  }

  public componentDidMount() {
    this.getData();
  }

  public componentDidUpdate(prevProps: IAppProps, prevState: IBlogAppState) {
    if (!prevState.isEditing && this.state.isEditing) {
      $('#createdOnDate').select().focus();
    }

    if (prevProps.app.appId !== this.props.app.appId) {
      this.getData();
    }
  }

  public render() {
    const { posts, selected } = this.state;

    return (
      <div className="applet-container">
        <div className="blog-post-list">
          <div className="applet-command-bar border-bottom d-flex flex-row">
            <form className="form-inline my-2 my-lg-0 mr-auto">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search Entries"
                aria-label="Search"
              />
            </form>

            <button
              type="button"
              id="new-post-button"
              className="btn btn-outline-secondary my-2"
              onClick={this.handleAddItem}
            >
              <i className="fas fa-plus" />
            </button>
          </div>

          {posts &&
            posts.map(post => (
              <div
                className={`blog-post-container ${
                  selected && post.entityId === selected.entityId
                    ? 'blog-post-selected'
                    : ''
                }`}
                key={post.entityId}
                data-entity-id={post.entityId}
                onClick={this.handleSelectListItem}
              >
                <div className="blog-post-header">{post.createdOnDate}</div>
                <div className="blog-post-body">
                  <p>{post.body}</p>
                </div>
              </div>
            ))}
        </div>

        <div className="blog-post-detail">
          <div className="applet-command-bar d-flex flex-row-reverse p-2">
            <button
              type="button"
              id="new-post-button"
              className="btn btn-outline-secondary"
              disabled={this.state.isEditing}
              onClick={this.handleEditItem}
            >
              <i className="fas fa-edit pr-2" />
              Edit
            </button>
            <button
              type="button"
              id="new-post-button"
              className="btn btn-outline-secondary mx-2"
              disabled={this.state.isEditing}
              onClick={this.handleDeleteItem}
            >
              <i className="fas fa-trash-alt pr-2" />
              Delete
            </button>
          </div>

          {selected && !this.state.isEditing && (
            <div className="blog-post-detail-container">
              <div
                className="blog-post-detail-date"
                style={{
                  border: '2px solid transparent',
                  paddingTop: '1px',
                  paddingBottom: '1px'
                }}
              >
                {selected.createdOnDate}
              </div>

              <div
                className="blog-post-detail-time"
                style={{
                  border: '2px solid transparent',
                  paddingTop: '1px',
                  paddingBottom: '1px'
                }}
              >
                {selected.createdOnTime}
              </div>

              <div
                className="blog-post-detail-body"
                style={{ border: '1px solid transparent', padding: '2px' }}
              >
                {selected.body}
              </div>
            </div>
          )}

          {selected && this.state.isEditing && (
            <div
              className="blog-post-detail"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '90%'
              }}
            >
              <input
                id="createdOnDate"
                name="createdOnDate"
                type="text"
                className="blog-post-detail-date"
                style={{ display: 'block', width: '100%' }}
                value={selected.createdOnDate}
                onChange={this.handleInputChange}
              />

              <input
                type="text"
                name="createdOnTime"
                className="blog-post-detail-time"
                style={{ display: 'block', width: '100%' }}
                value={selected.createdOnTime}
                onChange={this.handleInputChange}
              />

              <textarea
                name="body"
                className="blog-post-detail-body"
                style={{ display: 'block', width: '100%', flex: '1' }}
                value={selected.body}
                onChange={this.handleFieldChange}
              />

              <div style={{ textAlign: 'right', paddingTop: '4px' }}>
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={this.handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleSaveEdit}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private getData = () => {
    EntityDAL.getEntities(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.BLOG_POST,
      SortField.CREATED_ON,
      SortDirection.DESCENDING,
      BlogPostViewModel
    ).then((posts: BlogPostViewModel[]) => {
      this.setState({
        posts,
        selected:
          posts && posts.length ? new BlogPostViewModel(posts[0]) : undefined
      });
    });
  };

  private handleAddItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const createdOn = new Date().toISOString();
    const newPost = new BlogPostViewModel({
      entityId: '-1',
      createdOn,
      updatedOn: createdOn,
      title: '',
      body: ''
    } as IBlogPost);

    let { posts } = this.state;
    if (!posts) {
      posts = [newPost];
    } else {
      posts.unshift(newPost);
    }

    this.setState({
      posts,
      selected: new BlogPostViewModel(newPost),
      isEditing: true
    });
  };

  private handleEditItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      isEditing: true
    });
  };

  private handleDeleteItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    let { posts, selected } = this.state;

    EntityDAL.deleteEntity(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.BLOG_POST,
      selected!.entityId
    ).then((result: IDeleteEntityResponse) => {
      posts = posts!.filter(post => post.entityId !== result.entityId);
      this.setState({
        posts,
        selected: posts.length ? new BlogPostViewModel(posts[0]) : undefined
      });
    });
  };

  private handleSelectListItem = (e: React.MouseEvent<HTMLElement>) => {
    const entityId = e.currentTarget.dataset.entityId;
    const post = this.state.posts!.find(post => post.entityId === entityId);
    const selected = new BlogPostViewModel(post!);
    this.setState({
      selected
    });
  };

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.updateSelectedValue(e.currentTarget.name, e.currentTarget.value);
  };

  private handleFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.updateSelectedValue(e.currentTarget.name, e.currentTarget.value);
  };

  private updateSelectedValue = (name: string, value: string) => {
    const { selected } = this.state;
    (selected as any)![name] = value;
    this.setState({
      selected: selected
    });
  };

  private handleSaveEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const postBody = this.state.selected!.toModel();
    const isNew = postBody.entityId === '-1';
    const promise: Promise<IBlogPost> = isNew
      ? EntityDAL.addEntity(
          this.props.app.collectionId,
          this.props.app.appId,
          EntityType.BLOG_POST,
          postBody,
          BlogPostViewModel
        )
      : EntityDAL.updateEntity(
          this.props.app.collectionId,
          this.props.app.appId,
          EntityType.BLOG_POST,
          this.state.selected!.entityId,
          postBody,
          BlogPostViewModel
        );

    promise.then((result: IBlogPost) => {
      const { posts, selected } = this.state;
      const entry = posts!.find(e => e.entityId === selected!.entityId);
      entry!.entityId = result.entityId;
      entry!.createdOn = result.createdOn;
      entry!.body = result.body;
      selected!.entityId = result.entityId;
      this.setState({
        posts,
        selected,
        isEditing: false
      });
    });
  };

  private handleCancelEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const original = this.state.posts!.find(
      e => e.entityId === this.state.selected!.entityId
    )!;
    this.setState({
      isEditing: false,
      selected: new BlogPostViewModel(original)
    });
  };
}
