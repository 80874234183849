import * as React from 'react';
import * as routes from '../constants/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import CollectionPage from './Collection';
import InvitePage from './Invite';
import LandingPage from './Landing';
import LoginPage from './Login';
import MembershipPage from './Membership';
import NavigationBar from './Navigation';
import SettingsPage from './Settings';
import SignOutPage from './Signout';
import UserCollectionsPage from './UserCollections';

/**
 * React component which sets up all of the routes.
 */
class RouteDispatch extends React.Component<RouteComponentProps<any>, {}> {
  public render() {
    return (
      <div>
        <Switch>
          <Route exact={true} path={routes.LOGIN}>
            <NavigationBar isSimple={true} />
          </Route>
          <Route exact={true} path={routes.MEMBERSHIP}>
            <NavigationBar isSimple={true} />
          </Route>
          <Route exact={true} path={routes.SETTINGS}>
            <NavigationBar isSimple={true} />
          </Route>
          <Route exact={true} path={routes.USERCOLLECTIONS}>
            <NavigationBar isSimple={true} />
          </Route>
        </Switch>

        <Switch>
          <Route exact={true} path={routes.LANDING} component={LandingPage} />
          <Route exact={true} path={routes.LOGIN}>
            <div>
              <LoginPage />
            </div>
          </Route>
          <Route exact={true} path={routes.SIGNOUT} component={SignOutPage} />
          <Route exact={true} path={routes.SETTINGS} component={SettingsPage} />
          <Route
            exact={true}
            path={routes.MEMBERSHIP}
            component={MembershipPage}
          />
          <Route
            exact={true}
            path={routes.USERCOLLECTIONS}
            component={UserCollectionsPage}
          />
          <Route exact={true} path={routes.COLLECTION}>
            <CollectionPage />
          </Route>
          <Route exact={true} path={routes.APP}>
            <CollectionPage />
          </Route>
          <Route exact={true} path={routes.INVITE}>
            <InvitePage />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(RouteDispatch);
