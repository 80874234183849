import { IUserLoginResponse } from '@groupjitsu/common';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import './Login.css';
import { CollectionNavigation } from '../common/CollectionNavigation';
import { Session } from '../common/Session';
import { UsersDAL } from '../common/UsersDAL';

interface ILoginPageState {
  email?: string;
  password?: string;
  rememberMe?: boolean;
  errorMessage?: string;
}

class LoginPage extends Component<RouteComponentProps, ILoginPageState> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      errorMessage: undefined
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
  }

  public render() {
    return (
      <form id="loginForm" action="login.php" method="post">
        <Helmet bodyAttributes={{ style: 'background-color : #EEEEEE' }} />

        <div className="login-page-container">
          <div className="login-page">
            <h3>Log In to Kumomojo</h3>

            <div>
              <input
                type="text"
                value={this.state.email}
                id="email"
                name="email"
                className="form-control"
                placeholder="someone@example.com"
                onChange={this.handleFieldChange}
                autoFocus={true}
                style={{ width: '100%', margin: '15px 0 5px 0' }}
              />
            </div>
            <div>
              <input
                type="password"
                value={this.state.password}
                id="password"
                name="password"
                className="form-control"
                placeholder="password"
                onChange={this.handleFieldChange}
                style={{ width: '100%', margin: '5px 0' }}
              />
            </div>
            {this.state.errorMessage && (
              <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
            )}
            <div className="form-check">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  onChange={this.handleCheckboxChange}
                  checked={this.state.rememberMe}
                />
                Keep me signed in
              </label>
            </div>
            <div>
              <button
                className="btn btn-primary"
                onClick={this.handleSubmitClick}
                style={{ width: '100%', margin: '20px 0 15px 0' }}
              >
                Sign In
              </button>
            </div>
            <div>
              <a href="forgotpassword.php">Forgot your password?</a>
            </div>
          </div>
        </div>
      </form>
    );
  }

  private handleFieldChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  private handleCheckboxChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.checked
    });
  }

  /**
   * Submits the add/edit record data to the server.
   *
   * @param {object} e - Information about the event which initiated the submission.
   */
  private handleSubmitClick(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (!this.state.email) {
      this.setState({
        errorMessage: 'Please provide an e-mail address.'
      });
      return;
    }

    if (!this.state.password) {
      this.setState({
        errorMessage: 'Please enter your password.'
      });
      return;
    }

    UsersDAL.login(
      this.state.email!,
      this.state.password!,
      this.state.rememberMe!
    ).then((response: IUserLoginResponse) => {
      if (response.isAuthenticated) {
        Session.setUserId(response.userId!);
        CollectionNavigation.navigateToCollection(
          response.defaultCollectionId!,
          this.props.history
        );
      }
    });
  }
}

export default withRouter(LoginPage);
