import * as React from 'react';
import './CheckboxField.css';
import classnames from 'classnames';

export interface ICheckboxFieldChangeEvent {
  fieldName?: string;
  value: boolean;
  dataContext?: string;
}

interface ICheckboxFieldProps {
  name?: string;
  initialValue: boolean;
  onChange: (e: ICheckboxFieldChangeEvent) => boolean;
  className?: string;
  dataContext?: string;
}

interface ICheckboxFieldState {
  value: boolean;
}

/**
 * Custom checkbox field which renders a properly styled checkbox
 * rather than using the built-in checkbox element.  This ensures
 * consistent and custom rendering in all browsers.
 */
export class CheckboxField extends React.Component<
  ICheckboxFieldProps,
  ICheckboxFieldState
> {
  constructor(props: ICheckboxFieldProps) {
    super(props);
    this.state = {
      value: this.props.initialValue
    };
  }

  componentDidUpdate(prevProps: ICheckboxFieldProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({
        value: this.props.initialValue
      });
    }
  }

  public render() {
    return (
      <i
        className={classnames('km-checkbox-field', 'far', {
          'fa-check-circle': this.state.value,
          'fa-circle': !this.state.value
        })}
        onClick={this.handleClick}
      />
    );
  }

  private handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    this.props.onChange({
      fieldName: this.props.name,
      value: !this.state.value,
      dataContext: this.props.dataContext
    });
  };
}
