import * as React from 'react';

interface IErrorBannerProps {
  error?: string;
}

/**
 * Component that renders a dismissable error banner.
 */
export const ErrorBanner = (props: IErrorBannerProps) => {
  if (!props.error) {
    return null;
  }

  return (
    <div
      className="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      <strong>Oh no!</strong> {props.error}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};
