import {
  EntityType,
  INotebookPage,
  IDeleteEntityResponse,
  SortDirection,
  SortField
} from '@groupjitsu/common';
import Promise from 'bluebird';
import $ from 'jquery';
import React, { Component } from 'react';
import { EntityDAL } from '../../../common/EntityDAL';
import { NotebookPageViewModel } from '../../../viewmodels/NotebookPageViewModel';
import { ErrorBanner } from '../../controls/ErrorBanner';
import '../App.css';
import { IAppProps, IAppState } from '../common';
import './NotebookApp.css';
import { NotebookNav } from './NotebookNav';

interface INotebookAppState extends IAppState {
  pages?: NotebookPageViewModel[];
  selected?: NotebookPageViewModel;
  isEditing: boolean;
}

export class NotebookApp extends Component<IAppProps, INotebookAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      pages: undefined,
      selected: undefined,
      isEditing: false
    };
  }

  public componentDidMount() {
    this.getData();
  }

  public componentDidUpdate(
    prevProps: IAppProps,
    prevState: INotebookAppState
  ) {
    if (!prevState.isEditing && this.state.isEditing) {
      $('#createdOnDate').select().focus();
    }

    if (prevProps.app.appId !== this.props.app.appId) {
      this.getData();
    }
  }

  public render() {
    const { pages, selected } = this.state;

    return (
      <div className="applet-container">
        {/* Select Page Detail */}
        <div className="notebook-page-detail">
          <ErrorBanner error={this.state.error} />

          <div className="applet-command-bar d-flex flex-row-reverse py-2">
            <button
              type="button"
              id="edit-entry-button"
              className="btn btn-outline-secondary"
              disabled={this.state.isEditing}
              onClick={this.handleEditItem}
            >
              <i className="fas fa-edit pr-2" />
              Edit
            </button>
            <button
              type="button"
              id="delete-entry-button"
              className="btn btn-outline-secondary mx-2"
              disabled={this.state.isEditing}
              onClick={this.handleDeleteItem}
            >
              <i className="fas fa-trash-alt pr-2" />
              Delete
            </button>

            {selected && !this.state.isEditing && (
              <div className="notebook-page-detail-title">{selected.title}</div>
            )}

            {selected && this.state.isEditing && (
              <input
                id="title"
                name="title"
                type="text"
                className="notebook-page-detail-title"
                value={selected.title}
                onChange={this.handleInputChange}
              />
            )}
          </div>

          {selected && !this.state.isEditing && (
            <div className="notebook-page-detail-container">
              <div className="notebook-page-detail-body">{selected.body}</div>
            </div>
          )}

          {selected && this.state.isEditing && (
            <div className="notebook-page-detail-container">
              <textarea
                name="body"
                className="notebook-page-detail-body"
                value={selected.body}
                onChange={this.handleFieldChange}
              />

              <div className="notebook-page-button-container">
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={this.handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleSaveEdit}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>

        <NotebookNav
          pages={pages}
          selected={selected}
          onAddPage={this.handleAddPage}
          onSelectPage={this.handleSelectPage}
          onReorderPage={this.handleReorderPage}
        />
      </div>
    );
  }

  private getData = () => {
    EntityDAL.getEntities(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.NOTEBOOK_PAGE,
      SortField.SORT_ORDER,
      SortDirection.ASCENDING,
      NotebookPageViewModel
    ).then((pages: NotebookPageViewModel[]) => {
      this.setState({
        pages,
        selected:
          pages && pages.length
            ? new NotebookPageViewModel(pages[0])
            : undefined
      });
      return null;
    });
  };

  private handleAddPage = () => {
    const createdOn = new Date().toISOString();
    const newPage = new NotebookPageViewModel({
      entityId: '-1',
      createdOn,
      updatedOn: createdOn,
      title: 'Untitled Page',
      body: ''
    } as INotebookPage);

    let { pages } = this.state;
    if (!pages) {
      pages = [newPage];
    } else {
      pages.push(newPage);
    }

    this.setState({
      pages,
      selected: new NotebookPageViewModel(newPage),
      isEditing: true
    });
  };

  private handleEditItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({
      isEditing: true
    });
  };

  private handleDeleteItem = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    let { pages, selected } = this.state;

    EntityDAL.deleteEntity(
      this.props.app.collectionId,
      this.props.app.appId,
      EntityType.NOTEBOOK_PAGE,
      selected!.entityId
    ).then((result: IDeleteEntityResponse) => {
      pages = pages!.filter(page => page.entityId !== result.entityId);
      this.setState({
        pages,
        selected: pages.length ? new NotebookPageViewModel(pages[0]) : undefined
      });
    });
  };

  private handleSelectPage = (page: NotebookPageViewModel) => {
    this.setState({
      selected: new NotebookPageViewModel(page)
    });
  };

  private handleReorderPage = (
    page: NotebookPageViewModel,
    sortOrder: number
  ) => {
    // Optimistically update our state to reflect the reordering.
    const pages = this.state.pages!.map(p => new NotebookPageViewModel(p));
    const newPage = pages.find(p => p.entityId === page.entityId)!;
    pages.filter(p => p.sortOrder > page.sortOrder).forEach(p => p.sortOrder--);
    pages.filter(p => p.sortOrder >= sortOrder).forEach(p => p.sortOrder++);
    newPage.sortOrder = sortOrder;
    this.setState({
      pages: pages.sort((p1, p2) => (p1.sortOrder < p2.sortOrder ? -1 : 1))
    });

    // Save the change to the database.
    EntityDAL.setEntityOrder(
      page.collectionId,
      page.appId,
      EntityType.NOTEBOOK_PAGE,
      page.entityId,
      sortOrder,
      NotebookPageViewModel
    ).catch(err => {
      this.setState({
        error:
          'An error occurred while reordering your notebook.  Please refresh the page and try again.'
      });
    });
  };

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.updateSelectedValue(e.currentTarget.name, e.currentTarget.value);
  };

  private handleFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.updateSelectedValue(e.currentTarget.name, e.currentTarget.value);
  };

  private updateSelectedValue = (name: string, value: string) => {
    const { selected } = this.state;
    (selected as any)![name] = value;
    this.setState({
      selected: selected
    });
  };

  private handleSaveEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const postBody = this.state.selected!.toModel();
    const isNew = postBody.entityId === '-1';

    const promise: Promise<INotebookPage> = isNew
      ? EntityDAL.addEntity(
          this.props.app.collectionId,
          this.props.app.appId,
          EntityType.NOTEBOOK_PAGE,
          postBody,
          NotebookPageViewModel
        )
      : EntityDAL.updateEntity(
          this.props.app.collectionId,
          this.props.app.appId,
          EntityType.NOTEBOOK_PAGE,
          this.state.selected!.entityId,
          postBody,
          NotebookPageViewModel
        );

    promise
      .then((result: INotebookPage) => {
        const { pages, selected } = this.state;
        const index = pages!.findIndex(e => e.entityId === selected!.entityId);
        const newpages = pages!.slice();
        newpages[index] = new NotebookPageViewModel(result);
        this.setState({
          pages: newpages,
          selected: new NotebookPageViewModel(result),
          isEditing: false
        });
      })
      .catch((error: any) => {
        alert('Error!');
      });
  };

  private handleCancelEdit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const original = this.state.pages!.find(
      e => e.entityId === this.state.selected!.entityId
    )!;
    this.setState({
      isEditing: false,
      selected: new NotebookPageViewModel(original)
    });
  };
}
