import { IEntity } from '@groupjitsu/common';
import Formatter from '../common/ValueFormatter';

/**
 * Base view model for all entities.
 */
export class EntityViewModel {
  public entityId: string;
  public collectionId: string;
  public appId: string;
  public sortOrder: number;
  public createdOn: string;
  public createdBy: string;
  public updatedOn: string;
  public updatedBy: string;
  public createdOnDate: string;
  public createdOnTime: string;

  constructor(source: IEntity | EntityViewModel) {
    this.entityId = source.entityId;
    this.collectionId = source.collectionId;
    this.appId = source.appId;
    this.sortOrder = source.sortOrder;
    this.createdOn = source.createdOn;
    this.createdBy = source.createdBy;
    this.updatedOn = source.updatedOn;
    this.updatedBy = source.updatedBy;
    this.createdOnDate = Formatter.formatAsFullDate(source.createdOn);
    this.createdOnTime = Formatter.formatAsFullTime(source.createdOn);
  }

  public toModel(): IEntity {
    return {
      entityId: this.entityId,
      collectionId: this.collectionId,
      appId: this.appId,
      sortOrder: this.sortOrder,
      createdOn: this.createdOn,
      createdBy: this.createdBy,
      updatedOn: this.updatedOn,
      updatedBy: this.updatedBy
    };
  }
}
