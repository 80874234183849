import { IUserSignupResponse } from '@groupjitsu/common';
import { Formik, FormikActions, Form, Field, ErrorMessage } from 'formik';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Helmet from 'react-helmet';
import * as Yup from 'yup';
import { CollectionNavigation } from '../common/CollectionNavigation';
import { Session } from '../common/Session';
import { UsersDAL } from '../common/UsersDAL';
import './Invite.css';

interface IInviteFormValues {
  email: string;
  fullName: string;
  password: string;
  confirmPassword: string;
}

interface IInvitePageState {
  email?: string;
  fullName?: string;
  password?: string;
  confirmPassword?: string;
  errorMessage?: string;
}

class InvitePage extends Component<RouteComponentProps<any>, IInvitePageState> {
  private validationSchema: Yup.ObjectSchema<{}>;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      email: '',
      fullName: '',
      password: '',
      confirmPassword: '',
      errorMessage: undefined
    };

    this.validationSchema = Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address.')
        .required('Please enter your email address.'),
      fullName: Yup.string().required('Please enter your name.'),
      password: Yup.string().required('Please provide a password.'),
      confirmPassword: Yup.string()
        .required('Please confirm your password.')
        .oneOf([Yup.ref('password')], 'Passwords do not match')
    });
  }

  public componentDidMount() {
    // TODO: Fetch and validate the invitation using the invite ID in the URL.
  }

  public render() {
    return (
      <Formik
        initialValues={
          {
            email: '',
            fullName: '',
            password: '',
            confirmPassword: ''
          } as IInviteFormValues
        }
        onSubmit={this.inviteSignUp}
        validationSchema={this.validationSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Helmet bodyAttributes={{ style: 'background-color : #EEEEEE' }} />

            <div className="login-page-container">
              <h3>Kumomojo</h3>

              <h5>So and So has invited you to join Collection Name.</h5>

              <h6>"Here is my custom message."</h6>

              <div className="login-page">
                <span>Sign up below to create an account and get started.</span>
                <div>
                  <Field
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="someone@example.com"
                    autoFocus={true}
                    style={{ width: '100%', margin: '15px 0 5px 0' }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="email"
                    component="div"
                  />
                </div>
                <div>
                  <Field
                    type="text"
                    name="fullName"
                    className="form-control"
                    placeholder="Full Name"
                    style={{ width: '100%', margin: '15px 0 5px 0' }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="fullName"
                    component="div"
                  />
                </div>
                <div>
                  <Field
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="password"
                    style={{ width: '100%', margin: '5px 0' }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="password"
                    component="div"
                  />
                </div>
                <div>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="form-control"
                    placeholder="confirm password"
                    style={{ width: '100%', margin: '5px 0' }}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="confirmPassword"
                    component="div"
                  />
                </div>
                {this.state.errorMessage && (
                  <span style={{ color: 'red' }}>
                    {this.state.errorMessage}
                  </span>
                )}
                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary"
                    style={{ width: '100%', margin: '20px 0 15px 0' }}
                  >
                    Sign Up for Free
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  private inviteSignUp = (
    values: IInviteFormValues,
    actions: FormikActions<IInviteFormValues>
  ) => {
    UsersDAL.inviteSignUp(
      values.email,
      values.fullName,
      values.password,
      this.props.match.params.inviteId
    )
      .then((response: IUserSignupResponse) => {
        Session.setUserId(response.userId!);
        CollectionNavigation.navigateToCollection(
          response.defaultCollectionId!,
          this.props.history
        );
        actions.setSubmitting(false);
        alert('done');
        return null;
      })
      .catch(error => {
        actions.setSubmitting(false);
        alert('UNDONE: Error on signup');
      });
  };
}

export default withRouter(InvitePage);
