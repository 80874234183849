import { ITask } from '@groupjitsu/common';
import { EntityViewModel } from './EntityViewModel';

/**
 * View model for an individual task in the Tasks app.
 */
export class TaskViewModel extends EntityViewModel {
  public title: string;
  public description: string;
  public isComplete: boolean;

  constructor(source: ITask | TaskViewModel) {
    super(source);
    this.title = source.title;
    this.description = source.description;
    this.isComplete = source.isComplete;
  }

  public toModel(): ITask {
    return {
      ...super.toModel(),
      title: this.title,
      description: this.description,
      isComplete: this.isComplete
    };
  }
}
