import * as React from 'react';
import { Input } from 'reactstrap';
import { Key } from 'ts-keycode-enum';

export interface ITextFieldCommitEvent {
  fieldName?: string;
  value: string;
  dataContext?: string;
}

interface ITextFieldProps {
  name?: string;
  initialValue: string;
  onCommit: (e: ITextFieldCommitEvent) => boolean;
  lines?: number;
  clearOnCommit?: boolean;
  placeholder?: string;
  className?: string;
  dataContext?: string;
}

interface ITextFieldState {
  value: string;
}

export class TextField extends React.Component<
  ITextFieldProps,
  ITextFieldState
> {
  private suppressBlurCommit = false;

  constructor(props: ITextFieldProps) {
    super(props);
    this.state = {
      value: this.props.initialValue
    };
  }

  componentDidUpdate(prevProps: ITextFieldProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState({
        value: this.props.initialValue
      });
    }
  }

  public render() {
    const type = this.props.lines && this.props.lines > 1 ? 'textarea' : 'text';

    return (
      <Input
        type={type}
        name={this.props.name}
        placeholder={this.props.placeholder}
        value={this.state.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        className={this.props.className}
      />
    );
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: e.currentTarget.value
    });
  };

  private handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (this.suppressBlurCommit) {
      this.suppressBlurCommit = false;
      return;
    }

    this.commitValue(e.currentTarget, false);
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === Key.Enter) {
      e.preventDefault();
      this.commitValue(e.currentTarget, true);
    }
  };

  private commitValue(currentTarget: HTMLInputElement, autoBlur: boolean) {
    if (currentTarget.value !== this.props.initialValue) {
      if (
        this.props.onCommit({
          fieldName: this.props.name,
          value: currentTarget.value,
          dataContext: this.props.dataContext
        })
      ) {
        if (this.props.clearOnCommit) {
          this.setState({
            value: ''
          });
        } else {
          if (autoBlur) {
            this.suppressBlurCommit = true;
            currentTarget.blur();
          }
        }
      }
    }
  }
}
