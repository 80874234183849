import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppType } from '@groupjitsu/common';
import AppletIcon from './AppIcon';
import './AddAppModal.css';

export type RequestCloseHandler = () => void;
export type AddAppletHandler = (type: AppType, name: string) => void;

const AvailableApplets = [
  { type: AppType.CONTACTS, name: 'Contacts' },
  { type: AppType.CALENDAR, name: 'Calendar' },
  { type: AppType.DOCUMENT_LIBRARY, name: 'Document Library' },
  { type: AppType.APPLICANT_TRACKING, name: 'Applicant Tracking' },
  { type: AppType.INTERVIEW_SCHEDULING, name: 'Interview Scheduling' },
  { type: AppType.BLOG, name: 'Blog' },
  { type: AppType.ISSUE_TRACKING, name: 'Issue Tracking' },
  { type: AppType.TIMELINE, name: 'Timeline' },
  { type: AppType.JOURNAL, name: 'Journal' },
  { type: AppType.NOTEBOOK, name: 'Notebook' },
  { type: AppType.TASKS, name: 'Tasks' }
];

interface IAddAppletModalProps {
  isVisible: boolean;
  onRequestClose: RequestCloseHandler;
  onAddApplet: AddAppletHandler;
}

interface IAddAppletModalState {
  page: number;
  selected: AppType;
  appletName: string;
}

class AddAppletModal extends Component<
  IAddAppletModalProps,
  IAddAppletModalState
> {
  constructor(props: IAddAppletModalProps) {
    super(props);
    this.state = {
      page: 1,
      selected: AppType.CONTACTS,
      appletName: 'Contacts'
    };
  }

  componentDidUpdate(
    prevProps: IAddAppletModalProps,
    prevState: IAddAppletModalState
  ) {
    if (!prevProps.isVisible && this.props.isVisible) {
      this.setState({
        page: 1
      });
    }
  }

  public render() {
    return (
      <Modal
        isOpen={this.props.isVisible}
        centered={true}
        fade={false}
        toggle={this.toggle}
        size="lg"
      >
        <ModalHeader toggle={this.toggle}>Add Applet</ModalHeader>
        {this.state.page === 1 && (
          <React.Fragment>
            <ModalBody className="add-applet-modal-body">
              {AvailableApplets.map(applet => (
                <div
                  className={
                    'add-applet-icon-container' +
                    (applet.type === this.state.selected
                      ? ' add-applet-icon-container-selected'
                      : '')
                  }
                  onClick={this.handleIconClick}
                  data-applet-type={applet.type}
                >
                  <AppletIcon appletType={applet.type} large={true} />
                  <span>{applet.name}</span>
                </div>
              ))}
            </ModalBody>
            <ModalFooter>
              <button className="btn" onClick={this.toggle}>
                Cancel
              </button>
              <button className="btn" onClick={this.handleNextClick}>
                Next
              </button>
            </ModalFooter>
          </React.Fragment>
        )}
        {this.state.page === 2 && (
          <React.Fragment>
            <ModalBody className="add-applet-modal-body">
              <div className="form-group">
                <label>New Applet Name:</label>
                <input
                  type="text"
                  className="form-control"
                  autoFocus={true}
                  value={this.state.appletName}
                  onChange={this.handleNameChange}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <button className="btn" onClick={this.toggle}>
                Cancel
              </button>
              <button className="btn" onClick={this.handleBackClick}>
                Back
              </button>
              <button className="btn" onClick={this.handleSaveClick}>
                Create Applet
              </button>
            </ModalFooter>
          </React.Fragment>
        )}
      </Modal>
    );
  }

  private handleIconClick = (e: React.FormEvent<HTMLDivElement>) => {
    const type = e.currentTarget.dataset.appletType!;
    const name = AvailableApplets.find(applet => applet.type === type)!.name;
    this.setState({
      selected: type as AppType,
      appletName: name
    });
  };

  private handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      appletName: e.currentTarget.value
    });
  };

  private handleNextClick = (e: React.FormEvent<HTMLButtonElement>) => {
    this.setState({
      page: 2
    });
  };

  private handleBackClick = (e: React.FormEvent<HTMLButtonElement>) => {
    this.setState({
      page: 1
    });
  };

  private handleSaveClick = (e: React.FormEvent<HTMLButtonElement>) => {
    this.props.onAddApplet(this.state.selected, this.state.appletName);
  };

  private toggle = () => {
    this.props.onRequestClose();
  };
}

export default AddAppletModal;
