import { IInvite } from '@groupjitsu/common';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { CollectionNavigation } from '../common/CollectionNavigation';
import AddInviteModal from './AddInviteModal';
import './Settings.css';

interface IMembershipPageState {
  showAddInvite: boolean;
}

class MembershipPage extends Component<
  RouteComponentProps<any>,
  IMembershipPageState
> {
  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      showAddInvite: false
    };
  }

  public componentDidMount() {}

  public render() {
    const collectionId = this.props.match.params.collectionId;
    return (
      <div className="settings-outer-frame">
        <div className="settings-inner-frame">
          <div>
            <Link to={CollectionNavigation.buildCollectionUrl(collectionId)}>
              &lt; Back
            </Link>
          </div>

          <button className="btn" onClick={this.showAddInvite}>
            Add Invitation
          </button>

          <div>Show membership information here</div>
        </div>

        <AddInviteModal
          collectionId={collectionId}
          isVisible={this.state.showAddInvite}
          onRequestClose={this.handleAddInviteRequestClose}
          onAddInvite={this.handleAddInvite}
        />
      </div>
    );
  }

  private showAddInvite = () => {
    this.setState({
      showAddInvite: true
    });
  };

  private handleAddInviteRequestClose = () => {
    this.setState({
      showAddInvite: false
    });
  };

  private handleAddInvite = (invite: IInvite) => {
    alert('added');
  };
}

export default withRouter(MembershipPage);
