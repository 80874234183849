"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpResult = void 0;
/**
 * HttpClient wraps successful calls in a HttpResult that provides information
 * about the original request and metrics for analytics.
 */
var HttpResult = /** @class */ (function () {
    function HttpResult(method, url) {
        this.request = {
            method: method,
            url: url
        };
        this.initiatedAt = new Date();
    }
    HttpResult.prototype.applyData = function (data) {
        this.data = data;
        this.completedAt = new Date();
        this.duration = this.completedAt.getTime() - this.initiatedAt.getTime();
    };
    return HttpResult;
}());
exports.HttpResult = HttpResult;
