import {
  AppType,
  HttpClient,
  HttpResult,
  IAddAppRequest,
  IApp,
  IAppPatch,
  ICollectionInfo,
  IDeleteAppResponse,
  IInvite,
  INewInviteRequest,
  UserRole
} from '@groupjitsu/common';

export class CollectionDAL {
  /**
   * Retrieves the full detail for a given collection.
   *
   * @param collectionId Unique ID of the collection.
   */
  public static getCollectionInfo(collectionId: string) {
    return HttpClient.get<ICollectionInfo>(
      `/api/collections/${collectionId}`
    ).then((result: HttpResult<ICollectionInfo>) => {
      return result.data;
    });
  }

  /**
   * Retrieves all apps in a collection.
   *
   * @param collectionId Unique ID of the collection.
   */
  public static getCollectionApps(collectionId: string) {
    return HttpClient.get<IApp[]>(`/api/collections/${collectionId}/apps`).then(
      (result: HttpResult<IApp[]>) => {
        return result.data;
      }
    );
  }

  /**
   * Adds a new app to a collection.
   *
   * @param collectionId Unique ID of the parent collection.
   * @param type Type of app to add.
   * @param name Name of the new app.
   */
  public static addCollectionApp(
    collectionId: string,
    type: AppType,
    name: string
  ) {
    return HttpClient.post<IApp>(`/api/collections/${collectionId}/apps`, {
      type,
      name
    } as IAddAppRequest).then((result: HttpResult<IApp>) => {
      return result.data;
    });
  }

  /**
   * Changes the order of an app relative to its peers.
   *
   * @param collectionId Unique ID of the parent collection.
   * @param appId Unique ID of the app.
   * @param moveUp True to move up, false to move down.
   */
  public static reorderApp(
    collectionId: string,
    appId: string,
    moveUp: boolean
  ) {
    return HttpClient.patch<IApp>(
      `/api/collections/${collectionId}/apps/${appId}`,
      {
        orderDelta: moveUp ? -1 : 1
      } as IAppPatch
    ).then((result: HttpResult<IApp>) => {
      return result.data;
    });
  }

  /**
   * Deletes an existing app from a collection.
   *
   * @param collectionId Unique ID of the parent collection.
   * @param appId Unique ID of the app to delete.
   */
  public static deleteCollectionApp(collectionId: string, appId: string) {
    return HttpClient.delete<IDeleteAppResponse>(
      `/api/collections/${collectionId}/apps/${appId}`
    ).then((result: HttpResult<IDeleteAppResponse>) => {
      return result.data;
    });
  }

  /**
   * Creates a new invitation in the database.
   *
   * @param collectionId Unique ID of the collection for the invitation.
   * @param fromUserId Unique ID of the inviter.
   * @param inviteeEmail Email address of the invitee.
   * @param role Role of the invitee if they accept the invite.
   * @param message Message to the invitee from the inviter.
   */
  public static postInvite(
    collectionId: string,
    fromUserId: string,
    inviteeEmail: string,
    role: UserRole,
    message: string
  ) {
    const post: INewInviteRequest = {
      fromUserId,
      inviteeEmail,
      role,
      message
    };
    return HttpClient.post<IInvite>(
      `/api/collections/${collectionId}/invites`,
      post
    ).then((result: HttpResult<IInvite>) => {
      return result.data;
    });
  }
}
