import * as H from 'history';

export class CollectionNavigation {
  /**
   * Builds the URL for a group.
   *
   * @param groupId Unique ID of the group.
   * @param appName Unique name of the app in the group.
   */
  public static buildCollectionUrl(groupId: string) {
    return `/collections/${groupId}`;
  }

  /**
   * Builds the URL for an app in a group.
   *
   * @param groupId Unique ID of the group.
   * @param appName Unique name of the app in the group.
   */
  public static buildAppUrl(groupId: string, appName: string) {
    return `${CollectionNavigation.buildCollectionUrl(
      groupId
    )}/apps/${encodeURI(appName.toLowerCase())}`;
  }

  /**
   * Builds the URL for the group settings page.
   *
   * @param groupId Unique ID of the group.
   */
  public static buildSettingsUrl(groupId: string) {
    return `/collections/${groupId}/settings`;
  }

  /**
   * Builds the URL for the My Collections page for a user.
   *
   * @param userId Unique ID for the user.
   */
  public static buildUserCollectionsPageUrl(userId: string) {
    return `/users/${userId}/collections`;
  }

  /**
   * Navigates to the default app for a group.
   *
   * @param groupId Unique ID of the group.
   * @param history History object to use for navigation.
   */
  public static navigateToCollection(groupId: string, history: H.History) {
    history.push(CollectionNavigation.buildCollectionUrl(groupId));
  }

  /**
   * Navigates to the default app for a group.
   *
   * @param groupId Unique ID of the group.
   * @param history History object to use for navigation.
   */
  public static navigateToApp(
    groupId: string,
    appName: string,
    history: H.History
  ) {
    history.push(CollectionNavigation.buildAppUrl(groupId, appName));
  }
}
