import React, { Component } from 'react';
import logo from '../images/title-logo-white.png';
import './Navigation.css';

interface INavigationBarProperties {
  isSimple: boolean;
}

class NavigationBar extends Component<INavigationBarProperties, {}> {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark">
        <img src={logo} alt="App logo" />

        {!this.props.isSimple && (
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        )}

        {!this.props.isSimple && (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="mr-auto" />
            <ul className="navbar-nav my-2 my-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/settings">
                  Settings
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/user"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  user@company.com
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="/profile">
                    My Profile
                  </a>
                  <a className="dropdown-item" href="/password">
                    Change Password
                  </a>
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="/signout">
                    Log Out
                  </a>
                </div>
              </li>
            </ul>
          </div>
        )}
      </nav>
    );
  }
}

export default NavigationBar;
