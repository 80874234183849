import { IJournalEntry } from '@groupjitsu/common';
import { EntityViewModel } from './EntityViewModel';

/**
 * View model for an entry in a Journal applet.
 */
export class JournalEntryViewModel extends EntityViewModel {
  public body: string;

  constructor(source: IJournalEntry | JournalEntryViewModel) {
    super(source);
    this.body = source.body;
  }

  public toModel(): IJournalEntry {
    return {
      ...super.toModel(),
      body: this.body
    };
  }
}
