import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ICollection } from '@groupjitsu/common';
import { UsersDAL } from '../common/UsersDAL';
import './AddCollectionModal.css';

export type RequestCloseHandler = () => void;
export type AddCollectionHandler = (collection: ICollection) => void;

interface IAddCollectionModalProps {
  isVisible: boolean;
  onRequestClose: RequestCloseHandler;
  onAddCollection: AddCollectionHandler;
}

interface IAddCollectionModalState {
  name: string;
}

class AddCollectionModal extends Component<
  IAddCollectionModalProps,
  IAddCollectionModalState
> {
  constructor(props: IAddCollectionModalProps) {
    super(props);
    this.state = {
      name: ''
    };
  }

  public render() {
    return (
      <Modal
        isOpen={this.props.isVisible}
        centered={true}
        fade={false}
        toggle={this.toggle}
        size="lg"
      >
        <ModalHeader toggle={this.toggle}>Add Collection</ModalHeader>
        <ModalBody className="add-group-modal-body">
          <div className="form-group">
            <label htmlFor="group-name">Collection Name:</label>
            <input
              type="text"
              id="group-name"
              className="form-control"
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn" onClick={this.toggle}>
            Cancel
          </button>
          <button className="btn" onClick={this.handleSaveClick}>
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: e.currentTarget.value
    });
  };

  private handleSaveClick = (e: React.FormEvent<HTMLButtonElement>) => {
    UsersDAL.postUserCollection(this.state.name)
      .then((collection: ICollection) => {
        this.props.onAddCollection(collection);
      })
      .catch(error => {
        alert('UNDONE');
      });
  };

  private toggle = () => {
    this.props.onRequestClose();
  };
}

export default AddCollectionModal;
