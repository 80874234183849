enum SessionKey {
  UserId = 'user-id'
}

/**
 * Wrapper over session storage.
 */
export class Session {
  public static getUserId() {
    return sessionStorage.getItem(SessionKey.UserId);
  }

  public static setUserId(userId: string) {
    sessionStorage.setItem(SessionKey.UserId, userId);
  }
}
