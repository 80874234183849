import {
  HttpClient,
  HttpResult,
  ICollection,
  INewCollectionRequest,
  IUserCollection,
  IUserLoginRequest,
  IUserLoginResponse,
  IUserSignoutResponse,
  IUserSignupRequest,
  IUserSignupResponse,
  IValidateLoginTokenResponse
} from '@groupjitsu/common';
import { Session } from '../common/Session';

export class UsersDAL {
  /**
   * Sends a login request to the server.
   *
   * @param email Email address of the person logging in.
   * @param password Password of the person logging in.
   * @param rememberMe Whether or not to remember them.
   */
  public static async login(
    email: string,
    password: string,
    rememberMe: boolean
  ) {
    const postBody: IUserLoginRequest = {
      email,
      password,
      rememberMe
    };

    return HttpClient.post<IUserLoginResponse>(
      '/api/users/login',
      postBody
    ).then((result: HttpResult<IUserLoginResponse>) => {
      return result.data;
    });
  }

  /**
   * Validates the login token stored in a cookie,
   * if that cookie actually exists.
   */
  public static async validateLoginToken() {
    return HttpClient.get<IValidateLoginTokenResponse>(
      '/api/users/validatelogintoken'
    ).then((response: HttpResult<IValidateLoginTokenResponse>) => {
      return response.data;
    });
  }

  /**
   * Signs up a user in response to an invite.
   *
   * @param email Email address of the new user.
   * @param fullName Full name of the new user.
   * @param password User's password.
   * @param inviteId Unique ID of the invitation.
   */
  public static async inviteSignUp(
    email: string,
    fullName: string,
    password: string,
    inviteId: string
  ) {
    const postBody: IUserSignupRequest = {
      email,
      fullName,
      password,
      inviteId
    };

    return HttpClient.post<IUserSignupResponse>(
      '/api/users/invitesignup',
      postBody
    ).then((response: HttpResult<IUserSignupResponse>) => {
      return response.data;
    });
  }

  /**
   * Retrieves the list of collections to which the current user is a member.
   */
  public static async getUserCollections() {
    return HttpClient.get<IUserCollection[]>(
      `/api/users/${Session.getUserId()}/collections`
    ).then((response: HttpResult<IUserCollection[]>) => {
      return response.data;
    });
  }

  /**
   * Creates a new collection with the current user as the owner.
   *
   * @param name Name of the new collection.
   */
  public static async postUserCollection(name: string) {
    return HttpClient.post<ICollection>(
      `/api/users/${Session.getUserId()}/collections`,
      {
        name
      } as INewCollectionRequest
    ).then((response: HttpResult<ICollection>) => {
      return response.data;
    });
  }

  /**
   * Signs the user out.
   */
  public static async signout() {
    return HttpClient.post<IUserSignoutResponse>('/api/users/signout', {}).then(
      (response: HttpResult<IUserSignoutResponse>) => {
        return response.data;
      }
    );
  }
}
