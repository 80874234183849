import { IBlogPost } from '@groupjitsu/common';
import { EntityViewModel } from './EntityViewModel';

/**
 * View model for a post in a blog.
 */
export class BlogPostViewModel extends EntityViewModel {
  public title: string;
  public body: string;

  constructor(source: IBlogPost | BlogPostViewModel) {
    super(source);
    this.title = source.title;
    this.body = source.body;
  }

  public toModel(): IBlogPost {
    return {
      ...super.toModel(),
      title: this.title,
      body: this.body
    };
  }
}
