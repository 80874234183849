import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IInvite, UserRole } from '@groupjitsu/common';
import { CollectionDAL } from '../common/CollectionDAL';
import { Session } from '../common/Session';

interface IAddInviteModalProps {
  isVisible: boolean;
  collectionId: string;
  onRequestClose: () => void;
  onAddInvite: (invite: IInvite) => void;
}

interface IAddInviteModalState {
  email: string;
  role: UserRole;
}

class AddInviteModal extends Component<
  IAddInviteModalProps,
  IAddInviteModalState
> {
  constructor(props: IAddInviteModalProps) {
    super(props);
    this.state = {
      email: '',
      role: UserRole.CONTRIBUTOR
    };
  }

  public render() {
    return (
      <Modal
        isOpen={this.props.isVisible}
        centered={true}
        fade={false}
        toggle={this.toggle}
        size="lg"
      >
        <ModalHeader toggle={this.toggle}>Add Collection</ModalHeader>
        <ModalBody className="add-group-modal-body">
          <div className="form-group">
            <label htmlFor="group-name">Email Address:</label>
            <input
              type="text"
              id="email"
              className="form-control"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role:</label>
            <select
              id="group-type"
              className="form-control"
              value={this.state.role}
              onChange={this.handleRoleChange}
            >
              <option value={UserRole.ADMINISTRATOR}>Administrator</option>
              <option value={UserRole.CONTRIBUTOR}>Contributor</option>
              <option value={UserRole.VIEWER}>Viewer</option>
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn" onClick={this.toggle}>
            Cancel
          </button>
          <button className="btn" onClick={this.handleSaveClick}>
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: e.currentTarget.value
    });
  };

  private handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      role: e.currentTarget.value as UserRole
    });
  };

  private handleSaveClick = (e: React.FormEvent<HTMLButtonElement>) => {
    CollectionDAL.postInvite(
      this.props.collectionId,
      Session.getUserId()!,
      this.state.email,
      this.state.role,
      ''
    )
      .then((invite: IInvite) => {
        this.props.onAddInvite(invite);
      })
      .catch(error => {
        alert('UNDONE');
      });
  };

  private toggle = () => {
    this.props.onRequestClose();
  };
}

export default AddInviteModal;
