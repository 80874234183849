import {
  HttpClient,
  HttpResult,
  IDeleteEntityResponse,
  IEntity,
  IEntityPatch,
  IEntityPost,
  SortDirection,
  SortField
} from '@groupjitsu/common';
import { Session } from '../common';
import queryString from 'query-string';

export class EntityDAL {
  public static getEntities<E extends IEntity, VM>(
    collectionId: string,
    appId: string,
    entityType: string,
    sortField: SortField,
    sortDirection: SortDirection,
    viewModelType: new (e: E) => VM
  ) {
    const query = queryString.stringify({
      user: Session.getUserId(),
      sortField,
      sortDirection
    });
    return HttpClient.get<E[]>(
      `${EntityDAL.getRoutePrefix(collectionId, appId, entityType)}?${query}`
    ).then((result: HttpResult<E[]>) => {
      return result.data.map(entry => new viewModelType(entry));
    });
  }

  public static addEntity<E extends IEntity, P extends IEntityPost, VM>(
    collectionId: string,
    appId: string,
    entityType: string,
    entityPost: P,
    viewModelType: new (e: E) => VM
  ) {
    return HttpClient.post<E>(
      `${EntityDAL.getRoutePrefix(
        collectionId,
        appId,
        entityType
      )}?user=${Session.getUserId()}`,
      entityPost
    ).then((result: HttpResult<E>) => {
      return new viewModelType(result.data);
    });
  }

  public static updateEntity<E extends IEntity, P extends IEntityPatch, VM>(
    collectionId: string,
    appId: string,
    entityType: string,
    entityId: string,
    entityPatch: P,
    viewModelType: new (e: E) => VM
  ) {
    return HttpClient.put<E>(
      `${EntityDAL.getRoutePrefix(
        collectionId,
        appId,
        entityType
      )}/${entityId}?user=${Session.getUserId()}`,
      entityPatch
    ).then((result: HttpResult<E>) => {
      return new viewModelType(result.data);
    });
  }

  public static setEntityOrder<T extends IEntity, VM>(
    collectionId: string,
    appId: string,
    entityType: string,
    entityId: string,
    sortOrder: number,
    viewModelType: new (t: T) => VM
  ) {
    return HttpClient.patch<T>(
      `${EntityDAL.getRoutePrefix(
        collectionId,
        appId,
        entityType
      )}/${entityId}/sortOrder?user=${Session.getUserId()}`,
      { sortOrder }
    ).then((result: HttpResult<T>) => {
      return new viewModelType(result.data);
    });
  }

  public static deleteEntity(
    collectionId: string,
    appId: string,
    entityType: string,
    entityId: string
  ) {
    return HttpClient.delete<IDeleteEntityResponse>(
      `${EntityDAL.getRoutePrefix(
        collectionId,
        appId,
        entityType
      )}/${entityId}?user=${Session.getUserId()}`
    ).then((result: HttpResult<IDeleteEntityResponse>) => {
      return result.data;
    });
  }

  private static getRoutePrefix(
    collectionId: string,
    appId: string,
    entityType: string
  ): string {
    return `/api/collections/${collectionId}/apps/${appId}/entities/${entityType}`;
  }
}
