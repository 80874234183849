import { IUserSignoutResponse } from '@groupjitsu/common';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { UsersDAL } from '../common/UsersDAL';
import * as routes from '../constants/routes';

class SignOutPage extends Component<RouteComponentProps, {}> {
  componentDidMount() {
    UsersDAL.signout().then((response: IUserSignoutResponse) => {
      this.props.history.replace(routes.LANDING);
    });
  }

  render() {
    return <div />;
  }
}

export default withRouter(SignOutPage);
